import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const muna = () => {
	const { muna: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Muna Salama`]}
				description="About Dr Muna Salama"
			/>
			<ProfilePage
				title="Dr Muna Salama"
				name="Dr Muna Salama"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="Inflammatory bowel disease and all aspects of general adult luminal gastroenterology including colorectal cancer screening and surveillance, coeliac disease, eosinophilic oesophagitis and functional GI disorders."
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Fremantle Hospital, Perth, Western Australia",
					"Postgraduate Training: : Graduate Certificate in Population Health Studies, University of Western Australia",
				]}
				description={[
					"Dr Muna is an experienced, compassionate and well-rounded gastroenterologist. She has a specific interest in the management of inflammatory bowel disease (IBD) and understands that part of managing any chronic condition is developing relationships with patients built on good communication and trust.",
					"She is committed to delivering high quality endoscopy in gastroscopy and colonoscopy, and is experienced in dye-spray chromoendoscopy.",
				]}
				honours={[
					"",
				]}
				background="Dr Muna completed her gastroenterology training in Perth. She then undertook further studies in public health at the University of Western Australia and then returned to appointments at Sir Charles Gairdner Hospital and private practice in 2010."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth (Clinic)",
					"Private appointment - St. John of God Hospital, Subiaco (Procedures)",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
					"Public appointment - Osborne Park Hospital, Perth (open access endoscopy)",
				]}
				memberships={[
					"The Royal Australasian College of Physicians (RACP)",
					"European Crohn’s and Colitis Organisation (ECCO)",
					"Conjoint Committee for the Recognition of Training in Gastrointestinal Endoscopy",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
				]}
				personal={[
					"I love being active and particularly enjoy aerial activities including trapeze, lyra (aerial hoop) and silks, as well as running and yoga. I am happiest outdoors, and have an ever-growing number of plants and pets.",
				]}
				publications={[
					{
						publicationTitle: "Outcomes of endoscopic resection of large colorectal neoplasms: An Australian experience.",
						authors: "Salama M, Ormonde D, Quach T, Ee H, Yusoff I.",
					},
					{
						publicationTitle: "Stevens-Johnson syndrome complicating adalimumab therapy in Crohn's disease.",
						authors: "Salama M, Lawrance IC.",
					},
					{
						publicationTitle: "The inaccuracy of cystatin C and creatinine-based equations in predicting GFR in orthotopic liver transplant recipients.",
						authors: "Boudville N, Salama M, Jeffrey GP, Ferrari P.",
					},
					{
						publicationTitle: "Decline in native kidney function in liver transplant recipients is not associated with BK virus infection.",
						authors: "Salama M, Boudville N, Speers D, Jeffrey GP, Ferrari P.",
					},
				]}
			/>
		</>
	);
};

export default muna;
